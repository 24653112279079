import styles from "./deposits.module.scss";
import {LinkModal, handleOpen} from "blockmate-react-link";
import axios from "axios";
import toast from "react-hot-toast";
import {useConfig} from "../../../components/config/config";
import TopBar from "../../../components/deposits/topbar/TopBar";
import SideBar from "../../../components/deposits/sidebar/SideBar";
import DepositControls from "../../../components/deposits/deposit-controls/DepositControls";
import {useEffect, useState} from "react";
import TemporaryUserService from "../../../services/TemporaryUserService";

const DEPOSIT_DEMO_CLUID = "deposit_demo_app_cluid_second";
const MOBILE_WIDTH = 1400;

const Deposits = () => {
  const {getConfig} = useConfig();

  const supportedExchanges = getConfig("deposit_exchanges").split(",");
  const currency = getConfig("currency");

  const [jwt, setJwt] = useState();

  useEffect(() => {
    TemporaryUserService.getJWTbyCLUID(DEPOSIT_DEMO_CLUID).then(token => setJwt(token));
  }, []);

  const handleExchangeDeposit = (exchangeName, fiatAmount) => {
    const fakeInitializeExchangeDepositOnBackend = async () => {
      try {
        let base = getConfig("blockmate_api");
        if (!base.endsWith("/")) {
          base = base + "/";
        }
        const token = await TemporaryUserService.getJWTbyCLUID(DEPOSIT_DEMO_CLUID);
        const response = await axios.post(`${base}v1/exchange/deposit/initialize`,
          {
            "fiat_amount": fiatAmount,
            "fiat_currency": currency,
            "name": exchangeName,
          },
          {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          }
        );
        return {
          depositId: response?.data?.deposit_id,
          token,
        };
      } catch (error) {
        toast.error(error);
      }
    };
    if (!supportedExchanges.includes(exchangeName)) {
      return;
    }
    fakeInitializeExchangeDepositOnBackend().then(({depositId, token}) =>
      handleOpen(
        "deposit",
        undefined,
        undefined,
        {
          providerName: exchangeName,
          depositId,
          fiatAmount: String(fiatAmount),
          fiatCurrency: currency,
          jwt: token,
          merchantDescription: "Deposits Demo App",
          merchantIcon: "https://api.blockmate.io/v1/onchain/static/tron.png",
        }
      )
    );
  };

  const handleDirectDeposit = (fiatAmount) => {
    const fakeInitializeDirectDepositOnBackend = async () => {
      try {
        let base = getConfig("blockmate_api");
        if (!base.endsWith("/")) {
          base = base + "/";
        }
        const token = await TemporaryUserService.getJWTbyCLUID(DEPOSIT_DEMO_CLUID);
        const response = await axios.post(`${base}v1/exchange/deposit/direct/initialize`,
          {
            "fiat_amount": fiatAmount,
            "fiat_currency": currency,
          },
          {
            headers: {
              "Authorization": `Bearer ${token}`
            }
          }
        );
        return {
          depositId: response?.data?.deposit_id,
          token,
        };
      } catch (error) {
        toast.error(error);
      }
    };
    fakeInitializeDirectDepositOnBackend().then(({depositId, token}) =>
      handleOpen(
        "directDeposit",
        undefined,
        undefined,
        {
          depositId,
          jwt: token,
          merchantDescription: "Deposits Demo App",
          merchantIcon: "https://api.blockmate.io/v1/onchain/static/tron.png",
        }
      )
    );
  };

  return <div className={styles.container}>
    <LinkModal
      url={getConfig("link_url")}
      jwt={jwt}
      additionalUrlParams={{
        merchantDescription: "Deposits Demo App",
        merchantIcon: "https://api.blockmate.io/v1/onchain/static/tron.png",
      }}
    />
    <TopBar jwt={jwt} />
    <div className={styles.subcontainer}>
      <div className={styles.sidebarAndContentContainer}>
        <SideBar mobileWidth={MOBILE_WIDTH}/>
        <DepositControls handleExchangeDeposit={handleExchangeDeposit} handleDirectDeposit={handleDirectDeposit} mobileWidth={MOBILE_WIDTH}/>
      </div>
    </div>
  </div>;
};

export default Deposits;
